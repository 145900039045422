.App {
  width: 100%;
  height: 100%;

  tr.MuiTableRow-root {
    height: 0 !important;
  }
  tr.MuiTableRow-root:hover {
    background-color: lightgrey;
  }
}
