#ListTasksStudyReport {
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    padding: 0 !important;
    padding-right: 0.5em !important;
    padding-left: 0.5em !important;
  }
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone .MuiIconButton-root {
    padding: 0 !important;
  }

  .MuiToolbar-gutters {
    display: none;
  }
  tr.MuiTableRow-root:hover {
    background-color: lightgrey;
  }
}

.page-break {
  page-break-before: always;
}
