.drawer-container {
  overflow: hidden;
  position: relative;
  background-color: #2196f3;
}

.drawer-text {
  position: relative;
  padding: 20px;
}

.drawer-title {
  color: white;
}

.drawer-background {
  /* background-image: url("https://www.optimed-recrutement.com/wp-content/uploads/2018/04/recherche-powerup-1200-shutterstock.jpg"); */
  background-color: #2196f3;
  /* Full height */
  height: 200%;
  width: 200%;

  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.6;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-25%, -25%);

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.footer {
  position: absolute;
  bottom: 0;
  padding: 10px;
  padding-bottom: 20px;
  font-size: 0.8em;
}

@media print {
  .no-print {
    display: none;
  }
}
