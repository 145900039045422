#ListPlanning {
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    padding: 0.5em !important;
    padding-right: 0.5em !important;
    padding-left: 0.5em !important;
  }
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone .MuiIconButton-root {
    padding: 0 !important;
  }

  .MTableToolbar-root-126 {
    display: none;
  }
}
